import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn, doesUserHaveRole } from "../../components/accountManagement";
import { SideBarOptions, adminMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { CrudTable } from "../../components/crudTable";

const uri = "/admin/execution-logs/";

const AdminExecutionLogsPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  const DataRow = ({ data }) => {
    return (
      <>
        <td>{data.id}</td>
        <td>{data.log_source}</td>
        <td>{data.log_level}</td>
        <td>{data.log_date}</td>
        <td>{data.log_message}</td>
      </>
    );
  };

  return (
    <Layout pageInfo={{ pageName: "admin-execution-logs" }} sideBarOptions={SideBarOptions(adminMenuLinks, uri, "Admin")}>
      <Seo title="Admin Execution Logs" keywords={[`admin`]} description="Admin Execution Logs" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={adminMenuLinks} currentLink={uri} menuTitle="Admin" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Admin</Breadcrumb.Item>
            <Breadcrumb.Item active>Execution Logs</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      {isUserSignedIn(userData) && doesUserHaveRole(userData, "AUTOMATION_ADMIN") ? (
        <CrudTable
          url="/execution-logs"
          urlParams=""
          customFilters={[
            { name: "search_text", param_name: "search_text", default: "" },
            { name: "log_level", param_name: "log_level", default: "ALL" },
          ]}
          colHeaders={[
            "Id",
            "Source",
            "Level",
            "Time",
            "Message",
          ]}
          rowComponent={(data) => {
            return <DataRow data={data} />;
          }}
          sortOptions={[
            { name: "Id", key: "asc" },
            { name: "Id desc", key: "desc" },
          ]}
          defaultSort="desc"
        />
      ) : (
        <>Not logged in {navigate("/account/signin/")}</>
      )}
    </Layout>
  );
};

export default AdminExecutionLogsPage;
